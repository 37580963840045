/**
 * External dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Internal dependencies
 */
import Blockquote from '../components/blockquote';
import ClientList from '../components/client-list';
import Form from '../components/form';
import Hero from '../components/hero';
import Layout from '../components/layout';
import Section from '../components/section';
import SEO from '../components/seo';
import ButtonGroup from '../components/button-group';
import Button from '../components/button';


const FreelanceNextJsDeveloperPage = ( props ) => {
    const {
        location,
    } = props;

    return (
        <Layout
            location={ location }
            hideHeader
        >
            <SEO
                title="Freelance Next.js Developer"
                description="I'm a UK based freelance Next.js developer working with clients worldwide to build fast websites in React."
            />
            <Hero
                imageName="tom-hirst-headshot.png"
                imageAlt="I'm a freelance Next.js developer!"
                imageCaption="I'm a freelance Next.js developer!"
                noHeader
                standardHeight
            >
                <h1>Freelance Next.js Developer</h1>
                <p className="lead">I&apos;m a UK based freelance Next.js developer working with clients worldwide to build fast websites in React.</p>
                <p>Landing on this page, I guess you know what Next.js is already:</p>
                <ul className="check-list">
                    <li>
                        <FontAwesomeIcon icon="check" />
                        A JavaScript framework for modern front-ends based on React
                    </li>
                    <li>
                        <FontAwesomeIcon icon="check" />
                        "The React Framework for Production"
                    </li>
                    <li>
                        <FontAwesomeIcon icon="check" />
                        Super fast hybrid static and server rendering
                    </li>
                    <li>
                        <FontAwesomeIcon icon="check" />
                        Consumes daa from any back-end you wish to use
                    </li>
                </ul>
                <p>
                    Now you&apos;ve found your freelance web developer who knows Next.js!
                </p>
                <ButtonGroup>
                    <Button to="#hire-me" icon="arrow-right" text="Hire me today" color="green" anchor />
                    <Button to="#read-more" icon="arrow-down" text="Read more about me" anchor />
                </ButtonGroup>
            </Hero>
            <Section narrow>
                <h2 id="read-more">Why hire a freelance Next.js developer?</h2>
                <p>If you want a website that&apos;s:</p>
                <ul>
                    <li>Fast</li>
                    <li>Secure</li>
                    <li>Light-weight</li>
                    <li>Modern</li>
                    <li>Future-proof</li>
                </ul>
                <p>Then <strong><a href="https://nextjs.org/" target="_blank">Next.js</a> is the framework for you</strong>.</p>
                <p>If you want your website built by someone who:</p>
                <ul>
                    <li>Is trusted by businesses large and small</li>
                    <li>Will give you a fixed price for guaranteed completion of your work</li>
                    <li>Is personable, forthcoming with suggestions and easy to work with</li>
                </ul>
                <p>Then <strong>I&apos;m the freelance Next.js developer for you</strong>.</p>
                <h2>What does Tom Hirst know about being a freelance Next.js developer?</h2>
                <p>Next.js has become my go-to solution for creating performant front-end web experiences.</p>
                <p>I&apos;ve been working as a freelance web developer for over a decade and I believe that JavaScript is the way forward for website development.</p>
                <p>This is why I&apos;ve invested heavily into building sites for business using Next.js.</p>
                <p>As an experienced remote worker, I&apos;ve partnered with clients across the UK, the USA, Europe and Australia to work on their Next.js projects on a freelance basis.</p>
                <p>I come trusted by companies large and small, having worked on websites for the likes of the NHS, Facebook, BMW, Umbro and TGI
                    Fridays.</p>
                <p>I take on all aspects of Next.js website development on projects of all sizes. Whether your project is
                    small, medium or large, I can be your freelance Next.js developer.
                </p>
                <h2>I already have a CMS - can you develop a Next.js front-end for me?</h2>
                <p>100%. I can build a best in class Next.js website for you around your existing content management system.</p>
                <p>We can use multiple data sources or APIs to build your Next.js site too.</p>
                <p>Blog content from WordPress, eCommerce store powered by Shopify? We can bring those systems together in a singular Next.js front-end.</p>
                <h2>Can you build a back-end for us to use with Next.js too?</h2>
                <p>Absolutely. I&apos;m a full-stack developer.</p>
                <p>We can use WordPress, Contentful, GraphCMS or similar, allowing you to manage your own content.</p>
                <h2>Can you develop a Next.js website for us start to finish?</h2>
                <p>Yes. I can handle your website requirements from beginning to end.</p>
                <p>This would include planning, research, design, back end and front end development using Next.js.</p>
                <p>Also including rounds of improvement and aftercare after your website has gone live.</p>
                <h2 id="hire-me">Ready to start your Next.js project?</h2>
                <p>
                    I&apos;d love to work with you on your Next.js project.
                    Give me the details by filling out the form below:
                </p>
                <Form
                    id="contact-form"
                    contact
                    buttonAsideText="I'll reply as soon as I can!"
                    messagePlaceholder="Hi Tom, I have a Next.js project needing expert attention."
                    pathname={ location && location.pathname }
                />
                <h2>I&apos;ve worked on websites for</h2>
                <ClientList />
                <h2>I&apos;m experienced, trusted and easy to work with</h2>
                <div className="blockquote-list-with-middle">
                    <Blockquote
                        quote="Tom is structured, accountable, timely, stable and easy to talk to. I would recommend working with him to anyone who is on the lookout for a stellar senior developer."
                        cite="Hillevi Røstad, CAO at Dekode"
                        imageName="hr-headshot.jpg"
                        imageAlt="Hillevi Røstad"
                        boxed
                        mediumQuote
                    />
                </div>
                <h2>Ready to start moving your Next.js project forward?</h2>
                <p>We can get going <strong>today</strong>.</p>
                <a className="button is-green has-icon" href="#hire-me">
                    Yes! Take me back to the form
                    <FontAwesomeIcon icon="arrow-up" />
                </a>
            </Section>
        </Layout>
    );
};

FreelanceNextJsDeveloperPage.propTypes = {
    location: PropTypes.objectOf( PropTypes.any ),
};

FreelanceNextJsDeveloperPage.defaultProps = {
    location: {},
};

export default FreelanceNextJsDeveloperPage;
